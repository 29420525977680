import scrollingDates from '../modules/scrolling-dates';
import { reBuildFPonClick } from '../modules/fullpage';
import { addVerticalTabsClickEvents } from '../modules/vertical-tabs-content';
import { addInHouseCapabilitiesClickEvents } from '../modules/in-house-capabilities';

export default {
    init() {},
    finalize() {
        scrollingDates();
        reBuildFPonClick(['.c-accordion-grid summary', '.c-carousel summary']);
        addVerticalTabsClickEvents();
        addInHouseCapabilitiesClickEvents();
    },
};
