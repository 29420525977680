import { findEl } from '../util/findElement';

export default async () => {
  try {
    const contactDropdown = await findEl('.c-contact .hs-your_team');

    const contactDropdownLabel = contactDropdown.querySelector('label');
    contactDropdown.addEventListener('click', () => {
      if (window.fullpage_api) window.fullpage_api.reBuild();
    });

    contactDropdownLabel.addEventListener('click', () => {
      contactDropdown.classList.toggle('hs-your_team--open');
    });
  } catch {}
};
