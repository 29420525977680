import 'jquery';
import './style.scss';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import products from './routes/products';
import joinyondr from './routes/joinyondr';
import jobs from './routes/jobs';
import whatWeDo from './routes/what-we-do';
import ourPeople from './routes/our-people';
import suppliers from './routes/suppliers';
import contactUs from './routes/contact-us';
import tomorrowwithoutconstraints from './routes/tomorrowwithoutconstraints';
import woocommerceCheckout from './routes/woocommerce-checkout';
import ydrsl01 from './routes/ydrsl01';
import pageTemplateEventPage from './routes/page-template-event-page';
import locations from './routes/locations';
import newsroom from './routes/newsroom';
import pageTemplateNewsroomFaq from './routes/page-template-newsroom-faq';
import newsArticleTemplate from './routes/news-article-template';
import pressKits from './routes/press-kits';
import pageTemplateNewsroomSearch from './routes/page-template-newsroom-search';
import singlePressReleases from './routes/single-press-releases';
import singleNewsArticle from './routes/single-news-article';
import singleCompanyNews from './routes/single-company-news';
import singlePost from './routes/single-post';
import yondrIntel from './routes/yondr-intel';
import pageTemplateEventPageAlternate from './routes/page-template-event-page-alternate';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
    /** All pages */
    common,
    /** Home page */
    home,
    /** Products page */
    products,
    /** Join page */
    joinyondr,
    /** Jobs page */
    jobs,
    /** About us page */
    whatWeDo,
    /** Our people page */
    ourPeople,
    /** Contact page */
    contactUs,
    /** Suppliers page */
    suppliers,
    /** Event - Tomorrow without constraints */
    tomorrowwithoutconstraints,
    /** WooCommerce - Checkout Page */
    woocommerceCheckout,
    /** New site  */
    ydrsl01,
    /** Event Page Flexible Content */
    pageTemplateEventPage,
    /** Locations page */
    locations,
    /** Newsroom */
    newsroom,
    /** Newsroom FAQ */
    pageTemplateNewsroomFaq,
     /** News Article */
    newsArticleTemplate,
    /** Newsroom Press Kits */
    pressKits,
    /** Newsroom Listing */
    pageTemplateNewsroomSearch,
    /** Articles */
    singlePressReleases,
    singleNewsArticle,
    singleCompanyNews,
    singlePost,
    /** Intel Hub */
    yondrIntel,
    /** Alternate Event Page Flexible Content */
    pageTemplateEventPageAlternate,

});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
