import { gsap, Power1 } from 'gsap';
const navItems = [...document.querySelectorAll('.c-carousel--in-house-nav-item')];
const contentItems = [...document.querySelectorAll('.c-carousel--in-house-item')];
let activeIndex = 0;
let prevIndex = 0;

export function addInHouseCapabilitiesClickEvents() {
    // Allows for click navigation between sections
    navItems.forEach((item, index) => {
        item.addEventListener('click', () => {
            if (index === prevIndex) return;

            activeIndex = index;

            animateTabs(prevIndex, activeIndex);
            updateContent(prevIndex, activeIndex);

            prevIndex = activeIndex;
        });
    });
}

function animateTabs(prevIndex, activeIndex) {
    const fade = gsap.timeline();
    const activeTabBig = navItems[activeIndex].querySelector('.c-vertical-tabs-nav__item-text--big');
    const activeTab = navItems[activeIndex].querySelector('.c-vertical-tabs-nav__item-text');
    const prevTab = navItems[prevIndex].querySelector('.c-vertical-tabs-nav__item-text');
    const prevTabBig = navItems[prevIndex].querySelector('.c-vertical-tabs-nav__item-text--big');
    const rightY = activeIndex > prevIndex ? -45 : 45;
    const wordY = activeIndex > prevIndex ? 20 : -20;

    fade.fromTo(activeTabBig, { autoAlpha: 1, y: rightY }, { y: 0, duration: 0.4, ease: Power1.easeOut }, 0)
        .set(prevTabBig, { autoAlpha: 0 }, 0)
        .set(prevTab, { autoAlpha: 0.5, y: wordY }, 0)
        .to(prevTab, 0.3, { autoAlpha: 1, y: 0 }, 0.05)
        .set(activeTab, { autoAlpha: 1, y: wordY }, 0)
        .to(activeTab, 0.3, { autoAlpha: 1, y: 0 }, 0.05)
        .eventCallback('onStart', () => {
            navItems[prevIndex].classList.remove('c-carousel--in-house-nav-item--active');
            navItems[activeIndex].classList.add('c-carousel--in-house-nav-item--active');
        });
}

function updateContent(prevIndex, activeIndex) {
    const fade = gsap.timeline();
    const prevContentItem = contentItems[prevIndex];
    const activeContentItem = contentItems[activeIndex];

    fade.fromTo(
        prevContentItem,
        0.3,
        { autoAlpha: 1, x: 0 },
        { autoAlpha: 0, x: '-1%', ease: Power1.easeOut },
        0
    )
        .set(prevContentItem, { position: 'absolute' }, 0.31)
        .fromTo(
            activeContentItem,
            0.3,
            { autoAlpha: 0, x: '-1%' },
            { autoAlpha: 1, x: 0, position: 'relative', ease: Power1.easeOut },
            0.35
        )
        .eventCallback('onComplete', () => {
            prevContentItem.classList.remove('c-vertical-tabs-main__item--active');
            activeContentItem.classList.add('c-vertical-tabs-main__item--active');
        });
}
