import { throttle } from 'throttle-debounce';

export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const DESKTOP = 'desktop';
export const LARGE_DESKTOP = 'large-desk';

export let breakpoint = getBreakpoint();
export let isDesktop = isDesktopOrLargeDesktop();

const throttleResize = throttle(200, () => {
    breakpoint = getBreakpoint();
    isDesktop = isDesktopOrLargeDesktop();
});

window.addEventListener('resize', throttleResize);

function getBreakpoint() {
    if (window.innerWidth < 768) return MOBILE;
    if (window.innerWidth < 1025) return TABLET;
    if (window.innerWidth >= 1280) return LARGE_DESKTOP;
    if (window.innerWidth >= 1024) return DESKTOP;
}

export function isDesktopOrLargeDesktop() {
    return breakpoint === DESKTOP || breakpoint === LARGE_DESKTOP;
}
