import getCurrentUser from '../util/getCurrentUser';
import Player from '@vimeo/player';

export default async () => {
  // Masterclass event tracking
  const userData = await getCurrentUser();
  if (!userData) return;

  const masterclassAccordion = document.querySelector('#ga-masterclass-sections');
  if (!masterclassAccordion) return;

  const sections = masterclassAccordion.querySelectorAll('.ga-accordion-section');
  if (!sections) return;

  window.dataLayer = window.dataLayer || [];  

  sections.forEach((section, index) => {
    index++;

    const subSections = section.querySelectorAll('.ga-accordion-subsection');
    const sectionID = `masterclass-step-${index}`;

    if (!subSections.length) {
      handleAccordionEvents(section, sectionID);
    } else {
      subSections.forEach((subSection, indexB) => {
        indexB++;  
        const subSectionID = `${sectionID}-${indexB}`;
        handleAccordionEvents(subSection, subSectionID);
      })
    }
  })

  function handleAccordionEvents(section, sectionID) {
    const video = section.querySelector('iframe');
    const links = section.querySelectorAll('.ga-accordion-subsection-links a');

    let sectionOpened = false;

    // Ga event: Open Accordion
    section.addEventListener('click', () => {
      if (!sectionOpened) {
        dataLayer.push({
          event: sectionID,
          user_email: userData.username,
        })
        sectionOpened = true;
      }
    })

    handleVideoEvents(video, sectionID);
    handleLinkEvents(links, sectionID);
  }

  // Ga event: Watched Video
  function handleVideoEvents(video, sectionID) {
    if (video) {
      const player = new Player(video);

      player.on('ended', () => {
        dataLayer.push({
          event: `${sectionID}-video-ended`,
          user_email: userData.username,
        })
      });
    }
  }

  // Ga event: Link clicked
  function handleLinkEvents(links, sectionID) {
    if (links.length) {
      links.forEach(link => {
        link.addEventListener('click', () => {
          dataLayer.push({
            event: `${sectionID}-link-clicked`,
            user_email: userData.username,
            link_text: link.text,
            link_url: link.href,
          })
        })
      })
    }
  }
}