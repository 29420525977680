export default () => {
  const body = document.body;
  const scrollDown = 'scroll-down';
  const scrollUp = 'scroll-up';

  let lastKnownScrollPosition = 0;
  let currentScrollPosition;
  let scrollDirection = 'down';
  let ticking = false;

  document.addEventListener("scroll", (e) => {
    currentScrollPosition = window.scrollY;
    scrollDirection = currentScrollPosition > lastKnownScrollPosition ? 'down' : 'up';

    if (!ticking) {
      window.requestAnimationFrame(() => {

        if (scrollDirection === 'down') {
          body.classList.remove(scrollUp);
          body.classList.add(scrollDown);
        } else if (scrollDirection === 'up') {
          body.classList.remove(scrollDown);
          body.classList.add(scrollUp);
        }

        ticking = false;
      });

      ticking = true;
    }

    lastKnownScrollPosition = window.scrollY;
  });
};
