import { gsap, Power1 } from 'gsap';
import { throttle } from 'throttle-debounce';
const navItems = [...document.querySelectorAll('.c-vertical-tabs-content-nav__item')];
const contentItems = [...document.querySelectorAll('.c-vertical-tabs-content-main__item')];
const scrollableContainer = document.querySelector('.c-vertical-tabs-content-main');
let activeIndex = 0;
let prevIndex = 0;
let next;
let prev;
let isOverflowScroll = false;
const blockScrollDelay = 1000;
const numberOfTabs = navItems.length - 1;
const throttleScroll = throttle(blockScrollDelay, watchScroll);

export function addVerticalTabsClickEvents() {
    // Allows for click navigation between sections
    navItems.forEach((item, index) => {
        item.addEventListener('click', () => {
            if (index === prevIndex) return;

            activeIndex = index;

            animateTabs(prevIndex, activeIndex);
            updateContent(prevIndex, activeIndex);

            prevIndex = activeIndex;
        });
    });
}

export function addVerticalTabsScrollEvents() {
    setTimeout(() => window.addEventListener('wheel', throttleScroll, true), 1350);
}

function removeVerticalTabsScrollEvents() {
    window.removeEventListener('wheel', throttleScroll, true);
}

function watchScroll(e) {
    next = e.deltaY >= 1;
    prev = e.deltaY <= -1;

    handleOverflowScroll(e);

    if (!isOverflowScroll) {
        if (prev) {
            window.fullpage_api.moveSectionUp();
            removeVerticalTabsScrollEvents();
            return;
        }

        if (activeIndex === numberOfTabs && next) {
            window.fullpage_api.moveSectionDown();
            removeVerticalTabsScrollEvents();
            return;
        }
    }

    handleScroll();
}

// Uses mousewheel deltaY property to detect users scroll direction
function handleScroll() {
    if (!prev && !next) return;
    if (isOverflowScroll) return;

    if (next) activeIndex = prevIndex + 1;

    animateTabs(prevIndex, activeIndex);
    updateContent(prevIndex, activeIndex);

    prevIndex = activeIndex;
}

// Checks if user is scrolling within some overflowing content
function handleOverflowScroll(e) {
    const accordionOpen = document.querySelector('.c-vertical-tabs-content-main__item--active details[open]');
    if (!accordionOpen) return;

    const isOverflowing = scrollableContainer.scrollHeight > scrollableContainer.offsetHeight;
    const hasScrolledToBottom =
        Math.floor(scrollableContainer.scrollHeight - scrollableContainer.scrollTop) ===
            scrollableContainer.offsetHeight && e.deltaY >= 1;

    const hasScrolledToTop = scrollableContainer.scrollTop === 0 && e.deltaY <= -1;

    if (accordionOpen) {
        if (hasScrolledToBottom || hasScrolledToTop)
            // Adds delay to end of scroll section so it doesn't navigate to next section in same scroll movement
            setTimeout(() => (isOverflowScroll = false), blockScrollDelay);
        else if (isOverflowing) isOverflowScroll = true;
    }
}

function animateTabs(prevIndex, activeIndex) {
    const fade = gsap.timeline();
    const activeTabBig = navItems[activeIndex].querySelector('.c-vertical-tabs-content-nav__item-text--big');
    const activeTab = navItems[activeIndex].querySelector('.c-vertical-tabs-content-nav__item-text');
    const prevTab = navItems[prevIndex].querySelector('.c-vertical-tabs-content-nav__item-text');
    const prevTabBig = navItems[prevIndex].querySelector('.c-vertical-tabs-content-nav__item-text--big');
    const rightY = activeIndex > prevIndex ? -45 : 45;
    const wordY = activeIndex > prevIndex ? 20 : -20;

    fade.fromTo(activeTabBig, { autoAlpha: 1, y: rightY }, { y: 0, duration: 0.4, ease: Power1.easeOut }, 0)
        .set(prevTabBig, { autoAlpha: 0 }, 0)
        .set(prevTab, { autoAlpha: 0.5, y: wordY }, 0)
        .to(prevTab, 0.3, { autoAlpha: 1, y: 0 }, 0.05)
        .set(activeTab, { autoAlpha: 1, y: wordY }, 0)
        .to(activeTab, 0.3, { autoAlpha: 1, y: 0 }, 0.05)
        .eventCallback('onStart', () => {
            navItems[prevIndex].classList.remove('c-vertical-tabs-content-nav__item--active');
            navItems[activeIndex].classList.add('c-vertical-tabs-content-nav__item--active');
        });
}

function updateContent(prevIndex, activeIndex) {
    const fade = gsap.timeline();
    const prevContentItem = contentItems[prevIndex];
    const activeContentItem = contentItems[activeIndex];

    fade.fromTo(
        prevContentItem,
        0.3,
        { autoAlpha: 1, x: 0 },
        { autoAlpha: 0, x: '-1%', ease: Power1.easeOut },
        0
    )
        .set(prevContentItem, { position: 'absolute' }, 0.31)
        .fromTo(
            activeContentItem,
            0.3,
            { autoAlpha: 0, x: '-1%' },
            { autoAlpha: 1, x: 0, position: 'relative', ease: Power1.easeOut },
            0.35
        )
        .eventCallback('onComplete', () => {
            prevContentItem.classList.remove('c-vertical-tabs-content-main__item--active');
            activeContentItem.classList.add('c-vertical-tabs-content-main__item--active');
        });
}
