export default () => {
  const activeClass = 'c-mobile-navigation--active';
  const toggles = document.querySelectorAll('.mobile-navigation-toggle');
  const subMenuBtns = document.querySelectorAll('.c-mobile-navigation__sub-menu-btn');
  const menu = document.querySelector('.c-mobile-navigation');

  if (!toggles || !menu) return;

  let active = false;

  toggles.forEach((toggle) => {
    toggle.addEventListener('click', () => {
      active ? close() : open();
    });
  });

  subMenuBtns.forEach((subMenuBtn) => {
    subMenuBtn.addEventListener('click', (event) => {
      toggleDropdown(event);
    });
  });

  function open() {
    menu.classList.add(activeClass);
    active = true;
  }

  function close() {
    menu.classList.remove(activeClass);
    active = false;
  }

  function toggleDropdown(event) {
    const activeDropdownClass = 'c-mobile-navigation__sub-menu-btn--active';

    if (event.currentTarget.classList.contains(activeDropdownClass)) {
      event.currentTarget.classList.remove(activeDropdownClass);
    } else {
      event.preventDefault();
      event.currentTarget.classList.add(activeDropdownClass);
    }
  }
};
