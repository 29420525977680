export function handleFixedNav(sectionIndex, startIndex, lastIndex, nav) {
    if (sectionIndex === startIndex || sectionIndex === lastIndex - 1) {
        revealFixedNav(nav);
    } else if (sectionIndex === lastIndex || sectionIndex < startIndex || sectionIndex > lastIndex) {
        hideFixedNav(nav);
    }
}

export function revealFixedNav(nav) {
    if (!nav) return;

    nav.classList.remove('u-hidden');
    setTimeout(() => nav.classList.add('c-secondary-nav--enter'), 500);
}

export function hideFixedNav(nav) {
    if (!nav) return;

    nav.classList.remove('c-secondary-nav--enter');
    setTimeout(() => {
        if (!nav.classList.contains('c-secondary-nav--enter')) nav.classList.add('u-hidden');
    }, 2000);
}

export function updateProgressBars(index, nav) {
    if (!nav) return;

    const activeClass = 'c-progress-bars__line--active';
    const progressBars = nav.querySelectorAll('.c-progress-bars__line');
    if (index < 0 || index > progressBars.length - 1) return;

    progressBars[index].classList.add(activeClass);

    if (index !== progressBars.length - 1 && progressBars[index + 1].classList.contains(activeClass))
        progressBars[index + 1].classList.remove(activeClass);
}
