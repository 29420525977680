export default () => {
	jQuery(function ($) {

    $(".c-search-filter__pagination").on('click', function(event) {
      if (!event.target.classList.contains('page-numbers')) return;
  
      $('.page-numbers.current').removeClass('current');
      $(event.target).addClass('current');
    });

		$(document).on("sf:ajaxfinish", ".searchandfilter", function () {

			// if ($(".sf-input-text")[0].value !== undefined) {
			// 	alert(123);
			// }

			
			
			//Hide featured section if no active filters applied
			if (($(".sf-option-active")[0]) || ($(".sf-input-text")[0].value !== '')) {
				$("#intel-hub-featured").fadeOut();
			} else {
				$("#intel-hub-featured").fadeIn();
			}

			//Disable inputs if empty without hiding from UI
			$(".sf-level-0[data-sf-count]").each(function () {
				if ($(this).attr("data-sf-count") === "0") {
					$(this).addClass("sf-disabled");
					$(this).find(">:first-child").attr("disabled", "disabled");
				} else {
					$(this).removeClass("sf-disabled");
					$(this).find(">:first-child").removeAttr("disabled");
				}
			});
		});
	});
};
