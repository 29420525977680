import isPage from '../util/isPage';

export default () => {
    if (!window.hbspt.forms.create) return;

		let formID;

		switch (true) {
			case isPage("page-template-location"):
				formID = process.env.HUBSPOT_LOCATION_FORM_ID
				break;
			case isPage("page-template-capacity-europe"):
				formID = process.env.HUBSPOT_CAPACITY_EUROPE_FORM_ID;
				break;
			case isPage("contact-us"):
			case isPage("page-template-homepage"):
				process.env.HUBSPOT_CONTACT_FORM_ID;
				break;
			case isPage("page-template-newsroom"):
			case isPage("page-news"):
			case isPage("single-news-article"):
			case isPage("single-post"):
			case isPage("page-press-releases"):
			case isPage("single-press-releases"):
			case isPage("page-template-newsroom-search"):
			case isPage("page-template-newsroom-faq"):
			case isPage("page-template-press-kits"):
			case isPage("page-template-event-page"):
			case isPage("page-template-intel-hub"):
			case isPage("page-template-company-news"):
			case isPage("page-template-company-news-hub"):
			case isPage("single-company-news"):
				formID = process.env.HUBSPOT_NEWSLETTER_FORM_ID_NEWSROOM;
				break;
			case isPage("page-template-event-rsvp"):
				formID = process.env.HUBSPOT_RSVP_FORM_ID;
				break;
			case isPage("page-template-book-form"):
				formID = process.env.HUBSPOT_RSVP_BOOK_FORM_ID;
				break;
			case isPage("page-template-new-site"):
				formID = process.env.HUBSPOT_NEWSITE_FORM_ID;
				break;
			case isPage("page-template-event-rsvp-mandela"):
				formID = process.env.HUBSPOT_RSVP_MANDELA_USA;
				break;
		}


	if (formID) {
		window.hbspt.forms.create({
			portalId: process.env.HUBSPOT_PORTAL_ID,
			formId: formID,
			target: '.hubspot-form',
		});
	}

	// Allows hubspot form to be a flexible content block
	const flexibleHubspotForms = document.querySelectorAll('.hubspot-form-flexible-content');

	if (flexibleHubspotForms.length) {
		flexibleHubspotForms.forEach(form => {
			window.hbspt.forms.create({
				portalId: process.env.HUBSPOT_PORTAL_ID,
				formId: form.dataset.formId,
				target: '.hubspot-form-flexible-content',
			});
		})
	}
};
