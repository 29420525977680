export default () => {
  const sections = [...document.querySelectorAll('.c-rich-content-accordion__section')];
  const subSections = [...document.querySelectorAll('.c-video-dropdown')];

  // Update hash when navigating sections
  sections.forEach((section, index) => {
    const sectionID = section.id;
    const sectionTitle = section.querySelector('summary');
    
    sectionTitle.addEventListener('click', () => {
      window.location.hash = `#${sectionID}`
    })
  })

  subSections.forEach((subSection) => {
    const subSectionID = subSection.id;
    const subSectionTitle = subSection.querySelector('.c-video-dropdown__title');

    subSectionTitle.addEventListener('click', () => {
      window.location.hash = `#${subSectionID}`
    })
  })

  // Anchor to section on page load
  if (!window.location.hash) return;
  const sectionHash = window.location.hash;

  const activeSection = sections.find(section => section.id === sectionHash.slice(1));
  const activeSubSection = subSections.find(subSection => subSection.id === sectionHash.slice(1));

  if (activeSection) {
    activeSection.setAttribute('open', true);
  } else if (activeSubSection) {
    activeSubSection.parentNode.setAttribute('open', true);
    activeSubSection.classList.add('c-video-dropdown--open');
  }
};
