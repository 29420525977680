export default () => {
  const nav = document.querySelector('.c-navbar');
  if (!nav) return;

  const links = nav.querySelectorAll('.c-navbar__link');

  links.forEach((link) => {
    const dropdown = link.querySelector('.c-navbar__dropdown');
    if (dropdown) {
      link.addEventListener('mouseover', () => {
        dropdown.classList.add('c-navbar__dropdown--active');
        nav.style.height = `${dropdown.offsetTop + dropdown.offsetHeight + 30}px`;
      });
      link.addEventListener('mouseleave', () => {
        dropdown.classList.remove('c-navbar__dropdown--active');
        nav.style.removeProperty('height');
      });
    }
  });
};
