export default () => {
  const accordions = [...document.querySelectorAll('details')];

  accordions.forEach((accordion) => {
    const accordionHeader = accordion.querySelector('summary');
    const subAccordion = accordion.querySelectorAll('.c-sub-accordion');

    accordionHeader.addEventListener('click', (e) => {
      e.preventDefault();

      if (accordion.hasAttribute('open')) {
        accordion.removeAttribute('open');
      } else {
        accordion.setAttribute('open', '');
      }

      resetAccordions(accordion);
    });

    subAccordion.forEach(subAcc => {
      const subAccordionTitle = subAcc.querySelector('.c-sub-accordion__title');
      subAccordionTitle.addEventListener('click', () => {
        subAcc.classList.toggle('c-sub-accordion--open')
      })
    })
  });

  function resetAccordions(accordion) {
    accordions.forEach((acc) => {
      if (acc !== accordion) acc.removeAttribute('open');
    });
  }
};
