import { gsap, Power2, ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

export default {
  animateIn(section) {
    if (section && section.classList && section.classList.contains('c-section-parallax-wrapper')) {

      for (let i = 0; i < section.children.length; i++) {
        const contentTween = gsap.timeline({
          scrollTrigger: {
            trigger: section.children[i],
            start: 'top center',
            end: 'bottom center',
            scrub: true,
          },
        });
        const heading = section.children[i].querySelector('.c-section-parallax__subheading');
        const text = section.children[i].querySelector('.c-section-parallax__content-text');
        const imageMobile = section.children[i].querySelector('.c-section-parallax__content-image--mobile');
        const imageDesk = section.children[i].querySelector(
          '.c-section-parallax__content-image:not(.c-section-parallax__content-image--mobile)'
        );
        contentTween
          .from(heading,  0.8, { autoAlpha: 0, y: -400, ease: Power2.easeOut }, 0.2)
          .from(imageMobile, 0.8, { autoAlpha: 0, y: 150, ease: Power2.easeOut }, 0.35)
          .from(imageDesk, 0.8, { autoAlpha: 0, y: 150, ease: Power2.easeOut }, 0.35)
          .from(text, 0.8, { autoAlpha: 0, x: 50, ease: Power2.easeOut }, 0.5);
      }
    }
  },
};
