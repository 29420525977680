import { findEl } from '../util/findElement';

export default async () => {
  try {
    buildSubmitButton();
  } catch {}

  async function buildSubmitButton() {
    try {
      const formFooter = await findEl('.hs_submit');
      if (formFooter.querySelector('.c-link')) return;

      const submitBtn = formFooter.querySelector('.hs-button');
      submitBtn.classList.add('c-link__text');

      const submitBtnWrapper = document.createElement('div');
      submitBtnWrapper.classList.add('c-link', 'c-link--bounce');

      const arrowTransform = document.createElement('span');
      arrowTransform.classList.add('c-link__arrow-transform-right');

      const arrow = document.createElement('img');
      const showOnGray = formFooter.closest('.c-newsletter-signup');
      const showOnWhite = formFooter.closest('.c-hubspot-form-flexible');

      const arrowColour = showOnGray ? 'black' : showOnWhite ? 'white' : 'red';

      arrow.setAttribute('src', `/wp-content/themes/yondr-group-child/assets/svg/arrow-${arrowColour}.svg`);
      
      arrow.classList.add('c-link__arrow');

      submitBtn.parentNode.appendChild(submitBtnWrapper);
      submitBtnWrapper.appendChild(submitBtn);
      submitBtn.parentNode.insertBefore(arrowTransform, submitBtn.nextSibling);
      arrowTransform.appendChild(arrow);
    } catch {}
  }
};
