import { Swiper, Pagination, Mousewheel } from 'swiper';
import gsap from 'gsap';
import { breakpoint, isDesktop, LARGE_DESKTOP } from '../util/getBreakpoint';

Swiper.use([Pagination, Mousewheel]);

export default () => {
    const carousels = document.querySelectorAll('.swiper-container');

    carousels.forEach((carousel) => {
        const prevArrows = carousel.querySelectorAll('.js-carousel-prev');
        const nextArrows = carousel.querySelectorAll('.js-carousel-next');
        const labelsNav = carousel.querySelector('.c-carousel-labels-nav');
        const spacing = carousel.dataset.spaceBetween;
        const slidesPerViewDefault = carousel.dataset.slidesPerViewDefault;
        const slidesPerViewTablet = carousel.dataset.slidesPerViewTablet;
        const slidesPerViewDesk = carousel.dataset.slidesPerViewDesk;
        const slidesPerViewLargeDesk = carousel.dataset.slidesPerViewLargeDesk;
        const disableSwipeDesktop = carousel.dataset.disableSwipeDesktop === 'true' && isDesktop;
        const spacingDefaultAtMobile = carousel.dataset.spaceBetweenDefaultAtMobile === 'true';
        const slidesOffsetAfterLargeDesk = carousel.dataset.slidesOffsetAfterLargeDesk;
        const freeMode = carousel.dataset.freeMode === 'true';
        const loop = carousel.dataset.loop === 'true';

        const swiper = new Swiper(carousel, {
            allowTouchMove: !isDesktop,
            noSwiping: disableSwipeDesktop,
            freeMode,
            direction: 'horizontal',
            slidesPerView: slidesPerViewDefault ? parseFloat(slidesPerViewDefault) : 1,
            spaceBetween: spacing && !spacingDefaultAtMobile ? parseFloat(spacing) : 20,
            breakpoints: {
                768: {
                    slidesPerView: slidesPerViewTablet ? parseFloat(slidesPerViewTablet) : 2,
                    spaceBetween: spacing ? parseFloat(spacing) : 40,
                },
                1025: {
                    slidesPerView: slidesPerViewDesk ? parseFloat(slidesPerViewDesk) : 4,
                    spaceBetween: spacing ? parseFloat(spacing) : 40,
                },
                1280: {
                    slidesPerView: slidesPerViewLargeDesk ? parseFloat(slidesPerViewLargeDesk) : 4,
                    spaceBetween: spacing ? parseFloat(spacing) : 40,
                    slidesOffsetAfter: slidesOffsetAfterLargeDesk
                        ? parseFloat(slidesOffsetAfterLargeDesk)
                        : 0,
                },
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar',
            },
        });

        prevArrows.forEach(prevArrow => {
            if (prevArrow) {
                if (!loop) disableNavButton(prevArrow);

                prevArrow.addEventListener('click', () => swiper.slidePrev());
                if (!loop) {
                    swiper.on('reachBeginning', () => disableNavButton(prevArrow));
                    swiper.on('fromEdge', () => enableNavButton(prevArrow));
                }
            }
        });

        nextArrows.forEach(nextArrow => {
            if (nextArrow) {
                nextArrow.addEventListener('click', () => swiper.slideNext());
                if (!loop) {
                    swiper.on('reachEnd', () => disableNavButton(nextArrow));
                    swiper.on('fromEdge', () => enableNavButton(nextArrow));
                }
            }
        });

        if (labelsNav) {
            const tabs = carousel.querySelectorAll('.c-carousel-labels-nav__item');

            tabs.forEach((tab, index) => {
                tab.addEventListener('click', () => swiper.slideToLoop(index));
            });

            const navLabelScrollableContainer = carousel.querySelector(
                '.c-carousel-labels-nav__scrollable-container'
            );
            if (navLabelScrollableContainer) {
                animateLabels(carousel, swiper.realIndex);
                swiper.on('slideChange', (s) => animateLabels(carousel, s.realIndex));
            }
        }

        // Hides arrows if there is only one slide
        if (swiper.isEnd) carousel.classList.add('c-carousel--arrows-hide');
    });

    // Fullpage needs to recalculate heights after swiper has rendered
    // if (window.fullpage_api) setTimeout(() => window.fullpage_api.reBuild(), 50);

    function disableNavButton(button) {
        if (!button.classList.contains('c-carousel__nav-arrow--disabled'))
            button.classList.add('c-carousel__nav-arrow--disabled');
    }

    function enableNavButton(button) {
        if (button.classList.contains('c-carousel__nav-arrow--disabled'))
            button.classList.remove('c-carousel__nav-arrow--disabled');
    }

    function animateLabels(carousel, index) {
        const move = gsap.timeline();
        const scrollableContainer = carousel.querySelector('.c-carousel-labels-nav__scrollable-container');
        const tabs = carousel.querySelectorAll('.c-carousel-labels-nav__item');
        const activeTab = tabs[index];
        let xOffset = 0;

        if (activeTab && typeof activeTab.offsetLeft === 'number') {
            xOffset = breakpoint === LARGE_DESKTOP ? 0 : -activeTab.offsetLeft;
        }

        move.to(scrollableContainer, 0.3, { x: xOffset }, 0).eventCallback('onStart', () => {
            tabs.forEach((tab) => tab.classList.remove('c-carousel-labels-nav__item--active'));
            if (activeTab) {
                activeTab.classList.add('c-carousel-labels-nav__item--active');
            }
        });
    }
};
