export default () => {
    const officeData = JSON.parse(document.querySelector('[data-offices]').dataset.offices);
    const userEmail = document.querySelector('[data-offices]').dataset.userEmail;

    const formInputFields = [...document.querySelectorAll('form[name="checkout"] input')];
    const customDropdown = document.querySelectorAll('.woocommerce-custom-dropdown');
    const addressOptions = document.querySelectorAll('.woocommerce-office-select input');
    const billingFields = document.querySelector('.woocommerce-billing-fields');
    const selectOfficeDropdown = document.querySelector('.woocommerce-select-office');
    const officeChoice = document.querySelectorAll('.woocommerce-select-office input');

    const emailInput = document.querySelector('#billing_email');
    const addressLine1Input = document.querySelector('#billing_address_1');
    const addressLine2Input = document.querySelector('#billing_address_2');
    const cityInput = document.querySelector('#billing_city');
    const postcodeInput = document.querySelector('#billing_postcode');
    const countrySelect = document.querySelector('#billing_country');
    const countryOptions = countrySelect.querySelectorAll('option');

    formInputFields.push(countrySelect);

    resetFormFields();
    handleToggleAddressFields();
    autoFillAddress();
    handleCustomDropdown();

    // emailInput.readOnly = true;

    function resetFormFields() {
        formInputFields.forEach((field) => {
            switch (field.type) {
                case 'radio':
                    field.checked = false;
                    break;
                case 'text':
                    field.value = '';
                    break;
                case 'email':
                    field.value = '';
                    break;
                case 'tel':
                    field.value = '';
                    break;
                case 'select-one':
                    countryOptions.forEach((option) => {
                        option.selected = false;
                    });
                    break;
            }
        });
    }

    function handleCustomDropdown() {
        customDropdown.forEach((dropdown) => {
            const dropdownLabel = dropdown.querySelector('label');

            dropdownLabel.addEventListener('click', () =>
                dropdown.classList.toggle('woocommerce-custom-dropdown--open')
            );
        });
    }

    function handleToggleAddressFields() {
        // SHOW CHECKOUT FIELDS WHEN OFFICE OR OTHER IS SELECTED
        addressOptions.forEach((option) => {
            option.addEventListener('click', () => {
                if (option.value === 'Other' && option.checked) {
                    selectOfficeDropdown.classList.remove('woocommerce-select-office--show');
                    billingFields.classList.add('woocommerce-billing-fields--show');
                } else if (option.value === 'Office' && option.checked) {
                    selectOfficeDropdown.classList.add('woocommerce-select-office--show');
                    billingFields.classList.remove('woocommerce-billing-fields--show');
                }
            });
        });
    }

    function autoFillAddress() {
        if (userEmail !== 'null') emailInput.value = userEmail;

        // AUTOFILL CHECKOUT FIELDS WHEN OFFICE SELECTED
        officeChoice.forEach((option) => {
            option.addEventListener('click', () => {
                billingFields.classList.add('woocommerce-billing-fields--show');
                const matchedOfficeAddress = officeData.find(
                    (address) => address.title.toLowerCase() === option.value.toLowerCase()
                );

                if (!matchedOfficeAddress) return;

                addressLine1Input.value = matchedOfficeAddress.address_line_2;
                addressLine2Input.value = matchedOfficeAddress.address_line_3;
                // addressLine3Input.value = matchedOfficeAddress.address_line_3;
                cityInput.value = matchedOfficeAddress.city;
                postcodeInput.value = matchedOfficeAddress.postcode;
                countryOptions.forEach((option) => {
                    if (option.value === matchedOfficeAddress.country_code) option.selected = true;
                });
            });
        });
    }
};
