export default () => {
    const postCards = document.querySelectorAll('.c-post-card');
    if (!postCards.length) return;

    postCards.forEach((post) => {
        const link = post.querySelector('.c-post-card__link');
        const image = post.querySelector('.c-post-card__image');
        const targets = [link, image];

        targets.forEach((target) => {
            target.addEventListener('mouseover', () => {
                post.classList.add('c-post-card--active');
            });

            target.addEventListener('mouseleave', () => {
                post.classList.remove('c-post-card--active');
            });
        });
    });
};
