import { isDesktop } from '../util/getBreakpoint';

export default () => {
  const jobLinksWrapper = document.querySelector('.c-jobs__links');
  const jobsSection = document.querySelector('.c-jobs');
  if (!jobsSection) return;
  const jobLinks = [...jobsSection.querySelectorAll('.c-jobs__link')];
  const jobFilters = jobsSection.querySelectorAll('.c-jobs__filter');
  const jobsData = JSON.parse(jobsSection.dataset.jobs);
  let activeFilters = [];

  jobFilters.forEach((filter) => {
    filter.addEventListener('click', () => {
      const id = filter.dataset.jobsFilterId;
      const filterType = filter.dataset.jobsFilterType;

      handleActiveFilters(id, filterType);
      const matchedResults = filterResults(activeFilters)
      updateJobs(matchedResults);

      filter.classList.toggle('c-jobs__filter--active');
      if (!isDesktop) setTimeout(() => window.fullpage_api.reBuild(), 50);
    });
  });

  function handleActiveFilters(id, type) {
    const filter = { id, type };

    if (activeFilters.filter((e) => e.id === id).length) {
      activeFilters = activeFilters.filter((e) => e.id !== id);
    } else {
      activeFilters.push(filter);
    }
  }

  function updateJobs(matchedJobs) {
    let matchedJobElements = [];

    if (!activeFilters.length) matchedJobElements = jobLinks;
    else {
      jobLinks.forEach((job) => {
        matchedJobs.forEach((matchedJobData) => {
          if (job.dataset.jobId === String(matchedJobData.id)) matchedJobElements.push(job);
        });
      });
    }
    displayJobs(matchedJobElements);
  }

  function filterResults(activeFilters) {
    let matchedResults = [];

    activeFilters.forEach((filter) => {
      let filterType = filter.type;
      jobsData.forEach((job) => {
        if (job === null) return;

        let isMatch;

        const hasMultipleInstancesOfFilterType = Array.isArray(job[filterType])
          ? true
          : false;

        isMatch = hasMultipleInstancesOfFilterType
          ? job[filterType].filter((el) => String(el.id) === filter.id).length
          : job[filterType].id === filter.id;


        if (isMatch) matchedResults.push(job);
      });
    });
    return matchedResults;
  }

  function displayJobs(items) {
    jobLinksWrapper.innerHTML = '';

    items.forEach((item) => jobLinksWrapper.appendChild(item));
  }
};
