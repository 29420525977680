import { gsap, Power3, Draggable } from 'gsap/all';
import { isDesktop } from '../util/getBreakpoint';

export default () => {
    const locations = document.querySelector('.c-locations');
    if (!locations) return;

    const mapSVGWrapper = locations.querySelector('.c-locations__map');
    const mapDefault = locations.querySelector('.c-locations__map-default');
    const mapActive = locations.querySelector('.c-locations__map-active');
    const americaLines = mapDefault.querySelector('#America');
    const americaOutline = mapDefault.querySelector('#America_Outline');
    const emeaOutline = mapDefault.querySelector('#EMEA_Outline');
    const emeaLines = mapDefault.querySelector('#EMEA');
    const apacOutline = mapDefault.querySelector('#APAC_Outline');
    const apacLines = mapDefault.querySelector('#APAC');
    const indiaLines = mapDefault.querySelector('#India');
    const indiaOutline = mapDefault.querySelector('#India_Outline');
    const locationDropdowns = locations.querySelectorAll('.c-locations__dropdown');

    // Lines is the animated elements, Outline is the target for mouseevents
    const regionSVGElements = [
        [americaLines, americaOutline],
        [emeaLines, emeaOutline],
        [apacLines, apacOutline],
        [indiaLines, indiaOutline],
    ];

    // Maps the location name to the animated lines.
    const regionToSVGGroup = {
        americas: regionSVGElements[0][0],
        emea: regionSVGElements[1][0],
        apac: regionSVGElements[2][0],
        india: regionSVGElements[3][0],
    };

    // For mobile and tablet, scrolls location into view
    const regionScrollXPos = {
        americas: 0,
        emea: mapActive.offsetWidth / 4,
        apac: mapActive.offsetWidth / 2,
        india: mapActive.offsetWidth / 2,
    };

    initMap();

    // Handles map animations when interacting with the map svg
    function addSVGEvents() {
        regionSVGElements.forEach((regionArr, index) => {
            const regionOutline = regionArr[1];
            const regionFill = regionArr[0];
            const regionDropdown = locationDropdowns[index];
            const region = regionOutline.dataset.region;

            if (isDesktop) {
                regionOutline.addEventListener('mouseover', () => {
                    if (!isRegionActive(regionDropdown)) highlightMapRegion(regionFill);
                });

                regionOutline.addEventListener('mouseleave', () => {
                    if (!isRegionActive(regionDropdown)) unhighlightMapRegion(regionFill);
                });
            }

            regionOutline.addEventListener('click', (e) => {
                animateLocationDropdown(regionDropdown);
                isRegionActive(regionDropdown)
                    ? unhighlightMapRegion(regionFill)
                    : highlightMapRegion(regionFill);

                resetActiveRegions(regionDropdown);
                if (!isDesktop) scrollRegionIntoView(region);
            });
        });
    }

    // Handles map animation when toggling the location dropdowns
    function addDropdownEvents() {
        locationDropdowns.forEach((dropdown) => {
            const region = dropdown.dataset.region;
            const regionSVGElement = regionToSVGGroup[region];
            const dropdownTitle = dropdown.querySelector('.c-locations__dropdown-title');

            dropdownTitle.addEventListener('click', () => {
                animateLocationDropdown(dropdown);
                isRegionActive(dropdown)
                    ? unhighlightMapRegion(regionSVGElement)
                    : highlightMapRegion(regionSVGElement);

                resetActiveRegions(dropdown);
                if (!isDesktop) scrollRegionIntoView(region);
            });
        });
    }

    // Closes dropdown and removes highlight for any non-active map region
    function resetActiveRegions(activeRegion) {
        locationDropdowns.forEach((otherRegion) => {
            if (activeRegion !== otherRegion && isRegionActive(otherRegion)) {
                const region = otherRegion.dataset.region;
                const regionSVGElement = regionToSVGGroup[region];
                animateLocationDropdown(otherRegion);
                unhighlightMapRegion(regionSVGElement);
            }
        });
    }

    // Animates height and fades in location dropdown when opened
    function animateLocationDropdown(dropdown) {
        const tl = gsap.timeline();
        const dropdownList = dropdown.querySelector('.c-locations__dropdown-list');
        const isOpen = isRegionActive(dropdown);

        const heightValStart = isOpen ? 'auto' : 0;
        const heightValEnd = isOpen ? 0 : 'auto';
        const opacityStart = isOpen ? 1 : 0;
        const opacityEnd = isOpen ? 0 : 1;

        tl.fromTo(
            dropdownList,
            0.3,
            { height: heightValStart, autoAlpha: opacityStart },
            { height: heightValEnd, autoAlpha: opacityEnd, ease: Power3.easeOut },
            0
        ).eventCallback('onStart', () => {
            dropdown.classList.toggle('c-locations__dropdown--active');
        });
    }

    function scrollRegionIntoView(region) {
        gsap.to(mapSVGWrapper, { duration: 1, scrollLeft: regionScrollXPos[region], ease: Power3.easeOut });
    }

    function highlightMapRegion(region) {
        region.classList.add('active');
    }

    function unhighlightMapRegion(region) {
        region.classList.remove('active');
    }

    function isRegionActive(regionDropdown) {
        return regionDropdown.classList.contains('c-locations__dropdown--active');
    }

    function addDraggableToMap() {
        gsap.registerPlugin(Draggable);

        Draggable.create(mapSVGWrapper, {
            type: 'scrollLeft',
            allowEventDefault: true,
            minimumMovement: 6,
        });
    }

    function initMap() {
        const americaLines = mapDefault.querySelectorAll('#America_Lines path');
        const emeaLines = mapDefault.querySelectorAll('#EMEA_Lines path');
        const apacLines = mapDefault.querySelectorAll('#APAC_Lines path');
        const indiaLines = mapDefault.querySelectorAll('#India_Lines path');

        const tl = gsap.timeline();
        const continents = [...americaLines, ...emeaLines, ...apacLines, ...indiaLines, mapActive];

        tl.to(continents, 2, { autoAlpha: 1, stagger: 0.15, ease: Power3.easeOut }, 0.5);

        if (!isDesktop) {
            // Scrolls map to europe
            setTimeout(() => {
                scrollRegionIntoView('emea');
            }, 1000);
        }

        // Highlights europe and opens dropdown
        setTimeout(() => {
            animateLocationDropdown(locationDropdowns[1]);
            highlightMapRegion(regionSVGElements[1][0]);
        }, 1500);

        // Adds click/hover events after the animation has finished
        setTimeout(() => {
            addDropdownEvents();
            addSVGEvents();
            if (!isDesktop) addDraggableToMap();
        }, 2000);
    }
};
