// The afterEach router method calls before components are mounted to the DOM.
// Polling for the element guarantees that it will be found rather then returning undefined.
// This poller by default will check 10 times a second for 5 seconds and then error.
export async function findEl(selector, loopGuard = 1, interval = 100, maxLoops = 50) {
  let element = null;
  while (element === null) {
    if (loopGuard > maxLoops) throw new Error(`Element ${selector} not found.`);
    loopGuard += 1;
    element = document.querySelector(selector) || (await timeout(interval));
  }
  return element;
}

// Used to delay each iteration of the while loop
function timeout(interval) {
  return new Promise((resolve) => setTimeout(() => resolve(null), interval));
}
