import { reBuildFPonClick } from '../modules/fullpage';
import parallaxSections from '../modules/parallax-sections';

export default {
    init() {
        const home_parallax = document.querySelector('.c-section-parallax-wrapper');
        parallaxSections.animateIn(home_parallax);
    },
    finalize() {
        reBuildFPonClick(['.c-contact summary']);
    },
};
