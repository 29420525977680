export default async () => {
  // User data
  const isLoggedIn = document.body.classList.contains('logged-in');

  if(isLoggedIn) {
    const wpNonce = document.querySelector('#wp_nonce').value;

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-WP-Nonce': wpNonce
    });

    const user = await fetch('/wp-json/wp/v2/users/me?context=edit', {
      method: 'GET',
      headers
    });

    const userData = await user.json();
    return userData;
  }
}