import { throttle } from 'throttle-debounce';
import { isDesktop } from '../util/getBreakpoint';

export default () => {
    const customScrollBar = document.querySelectorAll('.c-custom-scrollbar');
    const scrollDelay = 600;
    let hasChangedSection = false;
    let isOverflowScroll = false;

    customScrollBar.forEach((scrollbar) => {
        const scrollableContainer = scrollbar.parentNode.querySelector(scrollbar.dataset.scrollableContainer);
        if (!scrollableContainer) return;

        const thumb = scrollbar.querySelector('.c-custom-scrollbar-thumb');
        const throttleScroll = throttle(10, () => moveScrollbar(scrollableContainer, thumb));

        scrollbar.style.height = scrollableContainer.offsetHeight + 'px';
        showScrollbar(scrollbar, scrollableContainer);

        scrollableContainer.addEventListener('scroll', throttleScroll, true);
        scrollableContainer.addEventListener('click', () => {
            setTimeout(() => showScrollbar(scrollbar, scrollableContainer), 300);
        });

        if (!scrollbar.dataset.disableNavigation) {
            const sectionContainer = scrollbar.dataset.section
                ? scrollbar.closest(scrollbar.dataset.section)
                : scrollableContainer;

            const throttleWheel = throttle(scrollDelay, (e) =>
                navigateNextSection(e, scrollableContainer)
            );
            sectionContainer.addEventListener('wheel', throttleWheel, true);
        }
    });

    function moveScrollbar(scrollableContainer, scrollThumb) {
        const scrollTop = scrollableContainer.scrollTop;
        const scrollBottom = scrollableContainer.scrollHeight - scrollableContainer.offsetHeight;
        let percentageIncrease = (scrollTop / scrollBottom) * 100;

        if (percentageIncrease <= 0) {
            percentageIncrease = 0;
        } else if (percentageIncrease >= 100) {
            percentageIncrease = 100;
        }

        scrollThumb.style.top = `${percentageIncrease}%`;
    }

    function showScrollbar(scrollbar, container) {
        if (container.scrollHeight > container.offsetHeight) {
            scrollbar.classList.add('c-custom-scrollbar--enter');
            container.classList.add('overflow-gradient');
        } else {
            scrollbar.classList.remove('c-custom-scrollbar--enter');
            container.classList.remove('overflow-gradient');
        }
    }

    function navigateNextSection(e, scrollableContainer) {
        if (!isDesktop) return;

        handleOverflowScroll(e, scrollableContainer);
        if (isOverflowScroll) return;

        const prev = e.deltaY <= -1;
        const next = e.deltaY >= 1;

        if (!hasChangedSection) {
            hasChangedSection = true;
            if (next) window.fullpage_api.moveSectionDown();
            if (prev) window.fullpage_api.moveSectionUp();
            setTimeout(() => (hasChangedSection = false), 900);
        }
    }

    function handleOverflowScroll(e, scrollableContainer) {
        const isOverflowing = scrollableContainer.scrollHeight > scrollableContainer.offsetHeight;
        const hasScrolledToBottom =
            scrollableContainer.scrollHeight - scrollableContainer.scrollTop <=
                scrollableContainer.offsetHeight && e.deltaY >= 1;

        const hasScrolledToTop = scrollableContainer.scrollTop <= 0 && e.deltaY <= -1;

        if (hasScrolledToTop || hasScrolledToBottom) {
            setTimeout(() => (isOverflowScroll = false), scrollDelay);
        } else if (isOverflowing) isOverflowScroll = true;
    }
};
