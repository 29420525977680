import vhCheck from 'vh-check';
import fullpage from '../modules/fullpage';
import desktopNav from '../modules/desktop-navigation';
import mobileNav from '../modules/mobile-navigation';
import customScrollbar from '../modules/custom-scrollbar';
import toggleAccordion from '../modules/toggle-accordion';
import hubspotForms from '../modules/hubspot-forms';
import hubspotSubmit from '../modules/hubspot-submit';
import contactForm from '../modules/contact-form';
import videoDropdown from '../modules/video-dropdown';
import ga from '../modules/ga';
import carousel from '../modules/carousel';

import richContentAccordionAnchoring from '../modules/rich-content-accordion-anchoring';

export default {
  init() {
    hubspotSubmit();
    hubspotForms();
    fullpage();
    vhCheck();
    desktopNav();
    mobileNav();
    customScrollbar();
    toggleAccordion();
    contactForm();
    videoDropdown();
    ga();
    richContentAccordionAnchoring();
    carousel();
  },
  finalize() {},
};
