import Player from '@vimeo/player';
import getCurrentUser from '../util/getCurrentUser';

export default async () => {
  const videoDropdowns = [...document.querySelectorAll('.c-video-dropdown')];
  const userData = await getCurrentUser();

  videoDropdowns.forEach((dropdown) => {
    const videoDropdownHeader = dropdown.querySelector('.c-video-dropdown__title');
    const videoIframe = dropdown.querySelector('iframe');
    const trackingCTA = dropdown.querySelector('.c-video-dropdown__tracking-cta');

    videoDropdownHeader.addEventListener('click', () => {
      dropdown.classList.toggle('c-video-dropdown--open')
      resetDropdowns(dropdown)
    })

    if (videoIframe && trackingCTA) {
      const player = new Player(videoIframe);

      player.on('ended', () => {
        trackingCTA.classList.add('c-video-dropdown__tracking-cta--show')

        if (userData.username) {
          const hiddenEmailField = trackingCTA.querySelector('.hs-email input');
          hiddenEmailField.value = userData.username;
        }
      });
    }
  });

  function resetDropdowns(dropdown) {
    videoDropdowns.forEach((d) => {
      if (d !== dropdown) d.classList.remove('c-video-dropdown--open');
    });
  }
};
