import { gsap, Power2 } from 'gsap';

export default (textWrapper, delay) => {
  const text = textWrapper.querySelectorAll('.u-stagger-text');
  if (!text.length) return;

  const tl = gsap.timeline();
  text.forEach((t) => gsap.set(t, { opacity: 0 }));
  tl.staggerFromTo(text, 0.2, { y: 30, opacity: 0 }, { y: 0, opacity: 1, ease: Power2.easeOut }, 0.05, delay);
};
