// Custom cursor div must be positioned at the root of the section as a first level child element.

import { isDesktop } from '../util/getBreakpoint';
import { throttle } from 'throttle-debounce';

export default () => {
  if (!isDesktop) return;

  const cursor = document.querySelector('.c-cursor');
  const customCursorSections = document.querySelectorAll('.c-carousel--about-us--mobile');
  const throttleMousemove = throttle(5, handleMousemove);
  const cursorMousedownClass = 'c-cursor--mousedown';
  const arrowRightClass = 'c-cursor--right';
  const cursorDisabledClass = 'c-cursor--disabled';
  let cursorIsOverRightHalfOfViewport = false;
  let firstSlide = true;
  let lastSlide = false;

  customCursorSections.forEach((section) => {
    const carousel = section.querySelector('.swiper-container');
    const wrapper = section.querySelector('.swiper-wrapper');

    wrapper.addEventListener('click', () => handleClick(carousel));
    wrapper.addEventListener('mouseover', () => showCustomCursor());
    wrapper.addEventListener('mouseleave', () => hideCustomCursor());
    wrapper.addEventListener('mousedown', () => handleMousedown());
    wrapper.addEventListener('mouseup', () => handleMouseup());

    carousel.addEventListener('mousemove', (event) => throttleMousemove(event));
    carousel.swiper.on('reachEnd', () => (lastSlide = true));
    carousel.swiper.on('reachBeginning', () => (firstSlide = true));
    carousel.swiper.on('fromEdge', () => {
      firstSlide = false;
      lastSlide = false;
    });
  });

  function handleMousemove(event) {
    const left = event.clientX;
    const top = event.clientY;

    cursor.style.left = `${left}px`;
    cursor.style.top = `${top}px`;

    cursorIsOverRightHalfOfViewport = event.clientX > window.innerWidth / 2;

    cursorIsOverRightHalfOfViewport
      ? cursor.classList.add(arrowRightClass)
      : cursor.classList.remove(arrowRightClass);

    disableCursor();
  }

  function handleClick(carousel) {
    cursorIsOverRightHalfOfViewport ? carousel.swiper.slideNext() : carousel.swiper.slidePrev();

    disableCursor();
  }

  function handleMousedown() {
    if (!cursor.classList.contains(cursorMousedownClass)) cursor.classList.add(cursorMousedownClass);
  }

  function handleMouseup() {
    if (cursor.classList.contains(cursorMousedownClass)) cursor.classList.remove(cursorMousedownClass);
  }

  function disableCursor() {
    if ((!cursorIsOverRightHalfOfViewport && firstSlide) || (cursorIsOverRightHalfOfViewport && lastSlide)) {
      cursor.classList.add(cursorDisabledClass);
    } else {
      cursor.classList.remove(cursorDisabledClass);
    }
  }

  function showCustomCursor() {
    cursor.style.display = 'block';
    document.body.style.cursor = 'none';
  }

  function hideCustomCursor() {
    cursor.style.display = 'none';
    document.body.style.cursor = 'auto';
  }
};
