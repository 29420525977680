import { throttle } from 'throttle-debounce';
import { isDesktop } from '../util/getBreakpoint';

export default () => {
    const sections = document.querySelectorAll('.c-scrolling-dates');
    if (!sections.length) return;

    sections.forEach((section) => {
        const sectionWrapper = section.querySelector('.c-scrolling-dates__inner');
        const listWrapper = section.querySelector('.c-scrolling-dates__list');
        const listItems = section.querySelectorAll('.c-scrolling-dates__year');
        const totalElement = section.querySelector('.c-scrolling-dates__total');
        const listItemOffsets = getListItemOffsets(listItems, listWrapper);
        const throttleScroll = throttle(10, (e) => scrollDates(e, listWrapper));
        let MWValues = generateMWValues(listItems);
        let total = MWValues[0];

        // Creates enough padding so that the final list item aligns with the base year
        listWrapper.style.paddingBottom =
            listWrapper.offsetHeight - listItems[listItems.length - 1].offsetHeight + 'px';
        listItems[0].classList.add('c-scrolling-dates__year--active');
        totalElement.innerText = total;

        sectionWrapper.addEventListener('wheel', throttleScroll, true);
        listWrapper.addEventListener(
            'scroll',
            () => {
                listItemOffsets.forEach((offset, index) => {
                    const isAbove = listWrapper.scrollTop >= offset - 15 ? true : false;

                    if (isAbove && !listItems[index].classList.contains('c-scrolling-dates__year--active')) {
                        listItems[index].classList.add('c-scrolling-dates__year--active');
                        total += getSumFromArray(MWValues[index]);
                        totalElement.innerText = total;
                    } else if (
                        !isAbove &&
                        listItems[index].classList.contains('c-scrolling-dates__year--active')
                    ) {
                        listItems[index].classList.remove('c-scrolling-dates__year--active');

                        total -= getSumFromArray(MWValues[index]);
                        totalElement.innerText = total;
                    }
                });
            },
            true
        );
    });

    function scrollDates(e, wrapper) {
        if (isDesktop) wrapper.scrollTop += e.deltaY;
    }

    // Extracts numbers from given string and pushes to array.
    function generateMWValues(items) {
        let values = [];
        items.forEach((item) => {
            const MWtext = item.querySelectorAll('.c-scrolling-dates__year-text');
            if (MWtext.length === 1) {
                const number = MWtext[0].innerText.match(/\d+/g);
                if (number) values.push(number.map(Number)[0]);
            } else {
                let subvalues = [];
                MWtext.forEach((text) => {
                    const number = text.innerText.match(/\d+/g);
                    if (number) subvalues.push(number.map(Number)[0]);
                });
                values.push(subvalues);
            }
        });
        return values;
    }

    function getListItemOffsets(items, wrapper) {
        let offsets = [];
        items.forEach((item) => {
            offsets.push(item.offsetTop - wrapper.offsetTop);
        });
        return offsets;
    }

    function getSumFromArray(arr) {
        return Array.isArray(arr) ? arr.flat().reduce((a, b) => a + b, 0) : arr;
    }
};
